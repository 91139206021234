import NextHead from "next/head";
import React from "react";

type HeadProps = {
  title?: string;
  description?: string | null;
  image?: string;
  children?: React.ReactNode;
};

const Head: React.FC<HeadProps> = ({ title, description, image, children }) => {
  const defaultDescription =
    "Empower your business with Prepit! Seamlessly blending into your operations, Prepit is your loyalty builder to drive long-term customer engagement and business growth.";
  const defaultImg = `/meta.jpg`;

  return (
    <NextHead>
      <title>{title ? `${title} | Prepit` : "Prepit | Loyalty Builder"}</title>
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={title ? `${title} | Prepit` : "Prepit | Loyalty Builder"}
      />
      <meta
        itemProp="name"
        content={title ? `${title} | Prepit` : "Prepit | Loyalty Builder"}
      />
      <meta
        itemProp="description"
        content={description || defaultDescription}
      />
      <meta name="description" content={description || defaultDescription} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta itemProp="image" content={image || defaultImg} />
      <meta property="og:image" content={image || defaultImg} />
      <meta
        property="og:title"
        content="Prepit: Turn Transactions into Connections with Our Loyalty Builder"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />{" "}
      {children}
    </NextHead>
  );
};

export default Head;
