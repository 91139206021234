import { useForm, SubmitHandler } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Listbox } from "@headlessui/react";
import ChevronDown from "@modules/common/icons/chevron-down";
import CurvyButton from "@modules/common/components/curvy-button";
import Calendar from "@modules/common/icons/calendar";
import countries from "./countries.json";
import axios from "axios";
import { useState } from "react";

// Reusable Text Input Component
const TextInput = ({
  label,
  placeholder,
  register,
  required,
  errorMessage,
  className = "",
}: {
  label?: string;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  required?: boolean;
  errorMessage?: string;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      {label && <label className="text-sm text-white">{label}</label>}
      <input
        {...register}
        placeholder={placeholder}
        required={required}
        className="w-full bg-inputPrimary border border-primary rounded-md p-3 focus:ring-1 focus:ring-primary text-white focus:outline-none"
      />
      {errorMessage && (
        <span className="text-red-500 text-xs">{errorMessage}</span>
      )}
    </div>
  );
};

// Reusable Dropdown Component
const Dropdown = ({
  label,
  options,
  selected,
  onChange,
  className = "",
  placeHolder,
  errorMessage,
  register,
}: {
  label?: string;
  options: { value: string; label: string }[];
  selected: string;
  onChange: (value: string) => void;
  className?: string;
  placeHolder?: string;
  errorMessage?: string; // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
}) => {
  return (
    <div className={`relative ${className}`}>
      {label && (
        <label className="text-sm text-white mb-1 block">{label}</label>
      )}
      <input type="hidden" {...register} />
      <Listbox value={selected} onChange={onChange}>
        <div className="relative">
          <Listbox.Button
            className={`w-full bg-inputPrimary border border-primary rounded-md p-3  text-left flex justify-between items-center focus:ring-1 focus:ring-primary text-white ${
              options.find((option) => option.value === selected)?.label
                ? ""
                : "text-opacity-60"
            }`}
          >
            {options.find((option) => option.value === selected)?.label ||
              placeHolder ||
              "Select"}
            <ChevronDown className="w-5 h-5 text-primary" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 w-full bg-darkPrimary border border-primary rounded-md mt-1 shadow-lg text-white overflow-clip max-h-[30vh] overflow-y-scroll">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option.value}
                className={({ active }) =>
                  `p-2 cursor-pointer ${active ? "bg-white text-primary" : ""}`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      {errorMessage && (
        <span className="text-red-500 text-xs">{errorMessage}</span>
      )}
    </div>
  );
};

// Main Form Component
type FormInputs = {
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  phoneNumber: string;
  posSystem: string;
  email: string;
  message: string;
};

const DemoForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormInputs>();

  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const countryOptions = Object.entries(countries).map(([code, name]) => ({
    value: code.toLowerCase(),
    label: name,
  }));

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      const response = await axios.post("/api/contact", data);
      console.log(response.data);
      reset();
      setRequestSuccess(true);
      setTimeout(() => setRequestSuccess(false), 3000);
    } catch (error) {
      console.error(error);
      setRequestError(true);
      setTimeout(() => setRequestError(false), 3000);
    }
  };

  return (
    <div
      id="contact"
      className="text-center md:text-left w-full grid grid-cols-1 md:grid-cols-2 gap-8 bg-darkGreen text-white p-6  shadow-md lg:px-48 md:py-20"
    >
      <div>
        <h2 className="text-3xl md:text-[2.5rem] font-bold text-primary leading-relaxed">
          Empower <span className="text-terryWhite ">your</span>
        </h2>
        <p className="text-3xl md:text-[2.5rem] font-semibold ">
          business with Prepit!
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-2 gap-5"
      >
        <TextInput
          placeholder="First name*"
          register={register("firstName", {
            required: "First name is required",
          })}
          errorMessage={errors.firstName?.message}
        />
        <TextInput
          placeholder="Last name*"
          register={register("lastName", {
            required: "Last name is required",
          })}
          errorMessage={errors.lastName?.message}
        />
        <TextInput
          placeholder="Company name*"
          register={register("companyName", {
            required: "Company name is required",
          })}
          errorMessage={errors.companyName?.message}
        />
        <Dropdown
          options={countryOptions}
          selected={watch("country")}
          onChange={(value) => {
            setValue("country", value);
          }}
          placeHolder="Country*"
          errorMessage={errors.country?.message}
          register={register("country", {
            required: "Country is required",
          })}
        />
        <div className="col-span-1">
          <PhoneInput
            country={watch("country")?.toLowerCase() || "eg"} // Set phone input country
            value={watch("phoneNumber")}
            onChange={(value) => setValue("phoneNumber", value)}
            inputClass="!bg-inputPrimary !border !border-primary !text-white !w-full !text-base !py-6"
            buttonClass="!bg-inputPrimary !border-primary "
            containerClass="w-full "
            dropdownClass="!bg-terryWhite !text-primary"
            inputProps={{
              required: true,
              placeholder: "Phone Number*",
              ...register("phoneNumber", {
                required: "Last name is required",
              }),
            }}
          />
          {errors.phoneNumber && (
            <span className="text-red-500 text-xs">
              {errors.phoneNumber.message}
            </span>
          )}
        </div>
        <Dropdown
          options={[
            { value: "Foodics", label: "Foodics" },
            { value: "Deliverect", label: "Deliverect" },
            { value: "Micros", label: "Micros" },
            { value: "Other", label: "Other" },
          ]}
          selected={watch("posSystem")}
          onChange={(val) => {
            setValue("posSystem", val);
          }}
          className="col-span-1"
          placeHolder="POS System"
          errorMessage={errors.posSystem?.message}
          register={register("posSystem", {})}
        />
        <TextInput
          placeholder="Email"
          register={register("email", {
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Enter a valid email address",
            },
          })}
          errorMessage={errors.email?.message}
          className="col-span-2"
        />
        <textarea
          {...register("message")}
          placeholder="Your message"
          className="col-span-2 bg-inputPrimary border border-primary rounded-md p-2 focus:ring-1 focus:outline-none focus:ring-primary text-white"
        />
        <CurvyButton
          type="submit"
          className="!bg-lightPrimary !font-medium text-darkGreen py-2.5 px-8 rounded-full justify-center w-max hover:!bg-midPrimary"
        >
          <Calendar />
          Book a Demo
        </CurvyButton>{" "}
        {requestSuccess && (
          <span className="text-green-500 text-sm">
            Your request has been submitted successfully
          </span>
        )}
        {requestError && (
          <span className="text-red-500 text-sm">
            Something went wrong. Please try again later
          </span>
        )}
      </form>
    </div>
  );
};

export default DemoForm;
